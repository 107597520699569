@import "../../layout";

.header {
  background-color: #000000;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 1000;
  // big
  @media (min-width: $lg) {
    align-items: center;
    padding: 30px 6.25%;
  }

  // small
  @media (max-width: $lg) {
    align-items: center;
    padding: 10px 10px;
  }

  .logo {
    // big
    @media (min-width: $lg) {
      width: 180px;
    }

    // small
    @media (max-width: $lg) {
      width: 120px;
    }
  }
  .section {
    display: flex;
    flex-direction: row;
    &.left {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      // big
      @media (min-width: $sm) {
        justify-content: flex-end;
      }

      // small
      @media (max-width: $sm) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: end;
      }
    }
  }
  .link {
    color: #ffffff;

    font-family: refrigerator-deluxe, sans-serif;
    text-decoration: none;
    font-weight: 500;
    font-size: 22px;

    // big
    @media (min-width: $sm) {
      margin-left: 4rem;
    }

    // small
    @media (max-width: $sm) {
      margin-right: 0rem;
    }
  }
}
