@import '../../layout';

.footer {
  background-color: #000000;

  display: flex;
  flex-direction: column;

  z-index: 1000;

  .footer-main {
    display: flex;
    flex-direction: column;

    border-bottom: solid 1px #ffffff;

    // big
    @media (min-width: $sm) {
      padding: 114px;
    }

    // small
    @media (max-width: $sm) {
      padding: 32px;
    }

    .footer-links {
      display: flex;

      // big
      @media (min-width: $sm) {
        flex-direction: row;
        justify-content: space-between;
      }

      // small
      @media (max-width: $sm) {
        flex-direction: column;
        align-items: center;
      }

      .link {
        color: #ffffff;

        text-decoration: none;

        font-family: refrigerator-deluxe, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 40.1387px;
        line-height: 48px;

        margin-left: 32px;
        margin-right: 32px;
      }
    }

    .footer-logo {
      align-self: center;
      width: 264px;
      margin-top: 64px;
      margin-bottom: 64px;
    }

    .footer-socials {
      // big
      @media (min-width: $sm) {
        align-self: flex-end;
      }

      // small
      @media (max-width: $sm) {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      img {
        width: 48px;
        margin-left: 8px;
      }
    }
  }

  .footer-copyright {
    padding-top: 32px;
    padding-bottom: 64px;
    text-align: center;

    font-family: interstate, sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 32px;

    color: #ffffff;
  }
}
