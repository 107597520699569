@import '../../custom.scss';
@import '../../layout';

.drop-0-allowlist-add {
  padding: 4rem;
  margin: 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    width: 100%;

    text-align: center;

    font-family: refrigerator-deluxe, sans-serif;
    font-style: normal;
    font-weight: 700;
    @media (min-width: $lg) {
      font-size: 54px;
      padding: 48px 160px 0px 160px;
    }
    @media (max-width: $lg) {
      font-size: 24px;
      padding: 20px;
    }
    line-height: 100%;

    text-transform: uppercase;

    color: #ffffff;
  }

  h3 {
    margin-bottom: 48px;
  }

  .connect-container {
    min-width: 500px;

    .label {
      font-family: interstate, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .join-list-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .drop0-button {
      font-family: refrigerator-deluxe, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
    }

    h2 {
      margin-bottom: 1rem;
    }

    p {
      font-family: interstate, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 1.5rem;
    }

    .error-text {
      font-family: interstate, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 12px;
      color: #ffffff;
      background-color: #aa0000;
      border-radius: 2px;
      border: #990000 solid 2px;
      padding: 4px 24px;
    }
  }
}

#root,
.page {
  background-color: #000000;
  max-width: 100% !important;

  color: #ffffff;
}
