@import '../custom.scss';
@import '../layout';

.redeem-form-dialog {
  z-index: 5;
}

.error-text {
  width: 100%;
  color: #ffffff;
  background-color: #d00000;
  padding: 8px;
  text-align: center;
}

.success-container {
  padding: 2rem;
  background-color: #ffffff;

  h2 {
    color: #000000;
    font-family: refrigerator-deluxe, sans-serif;
    font-style: normal;
    font-weight: 700;

    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #000000;
  }

  p {
    color: #000000;
    font-family: interstate, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0.15px;

    max-width: 520px;
  }

  hr {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #000000;
    opacity: 0.8;
  }
}
