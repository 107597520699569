$primary: #4e44ce;

$font-primary: #202020;
$font-error: #e7c1c8;

$theme-colors: (
  'primary': $primary,
);

.btn {
  background-color: #4e44ce;

  padding: 15px 40px !important;

  font-family: refrigerator-deluxe, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1px;

  text-align: center;

  color: #ffffff;

  border-radius: 0px !important;
  border-width: 0px !important;

  &:hover {
    background-color: #433bad;
  }

  &:active {
    background-color: #433bad;
  }

  &:focus {
    background-color: #433bad;
  }

  &.btn-outline-secondary {
    border: 1px solid #23262f !important;

    &:hover {
      background-color: #23262f;
      color: white;
    }
  }

  // &:disabled {
  //   background-color: #362f90;
  // }
}

@import '~bootstrap/scss/bootstrap';
