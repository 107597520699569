@import 'layout';
@import 'custom';

html,
body,
#root {
  height: 100%;
  font-family: interstate, sans-serif;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.App {
  height: 100%;

  .parent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content {
    flex: 1 0 auto;
  }
}

.overlay {
  background-color: #000;
  border-radius: inherit;
  height: 100%;
  opacity: 40%;
  position: absolute;
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.clickable {
  cursor: pointer;
}

.redemption-closed {
  height: 450px;
  font-size: 28px;
  font-weight: bold;
  margin-top: 180px;
}
