@import '../../custom.scss';
@import '../../layout';

#root,
.page {
  background-color: #000000;
  max-width: 100% !important;

  color: #ffffff;
}

.drop2-reactor {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  border: solid 1px #ffffff;

  // big
  @media (min-width: $lg) {
    padding: 92px;
    margin: 92px;
  }

  // small
  @media (max-width: $lg) {
    padding: 32px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    border: solid 1px #ffffff;

    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;

    // big
    @media (min-width: $lg) {
      top: -16px;
      left: 16px;
    }

    // small
    @media (max-width: $lg) {
      top: -8px;
      left: 8px;
    }
  }

  h1 {
    font-family: refrigerator-deluxe, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 72px;

    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #ffffff;
  }

  p {
    font-family: interstate, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    letter-spacing: 0.15px;

    color: #ffffff;
  }

  hr {
    margin-top: 72px;
    margin-bottom: 72px;
    background-color: #ffffff;
    opacity: 1;
  }

  .connect-wallet-button {
    margin-top: 32px;
  }

  .reactor-error-text {
    color: #ffffff;
    background-color: #d00000;
    padding: 2px 48px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
  }

  .reactor-warning-text {
    color: #ffffff;
    background-color: #e89f17;
    padding: 8px 48px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;

    a {
      color: #ffffff;
    }
  }

  .drop2-reactor-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .drop2-reactor-card {
      width: 350px;
      background-color: #ffffff;
      border-radius: 4px;
      margin: 16px;

      .drop2-reactor-image {
        width: 100%;
      }

      p {
        color: #000000;
      }

      .drop2-reator-is-redeemed {
        font-family: refrigerator-deluxe, sans-serif;
        font-size: 24px;
        font-weight: 600;

        letter-spacing: 0.15px;
        text-transform: uppercase;
      }

      .drop2-reactor-redeem-button {
        padding: 10px 60px !important;
      }
    }
  }
}
