body {
  padding: 0;
  margin: 0;
  font-family: interstate, sans-serif;
}

a,
.link {
  color: #3263a4;
  cursor: pointer;
}

.navbar {
  padding: 30px 50px;
  background-color: #000;
  background: #000;
  color: #fff;
  text-align: center;
}

.logo {
  width: 160px;
}

.page {
  /* max-width: 1200px; */
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.loading {
  align-items: center;
  flex-direction: column;
}

.jumbo {
  max-width: 100%;
  padding-top: 60px;
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(https://uploads-ssl.webflow.com/60c8b883026e8472e92ab039/6186b09605f7db5a39fa93c3_dots-bg.jpg);
  background-position: 0 0;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.jumbo .heading {
  color: #fff !important;
  margin-bottom: 32px;
}

.jumbo .subheading {
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 400;
  text-align: center;
  max-width: 500px;
  display: block;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 20px;
}

.jumbo a,
.jumbo a:visited {
  color: #fff;
}

.steps-list {
  text-align: left;
  max-width: 600px;
  margin: 40px auto;
  margin-bottom: 60px;
}

.heading {
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 48px;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.heading1 {
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 36px;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 0;
  text-align: center;
}

.heading2 {
  font-family: refrigerator-deluxe, sans-serif;
  line-height: 50px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.heading3 {
  font-size: 18px;
  font-weight: 700;
}

.step {
  margin-bottom: 30px;
  border: 1px solid;
  padding: 32px;
  margin-top: 30px;
  border-radius: 6px;
}

.arrow-down {
  font-size: 42px;
}

/* .btn {
  padding: 7px 20px 8px;
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 4px;
  background-color: #316195;
  border: none;
  text-align: center;
  transition: background-color 0.4s, opacity 0.4s, color 0.4s;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.btn:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.btn:hover:enabled {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
} */

.add-email-form {
  margin-bottom: 16px;
}

.add-email-form .text-input {
  border: 1px solid #000;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 8px;
  margin-right: 0.5em;
  width: 50%;
  border-radius: 3px;
}

.redeem-nft-form .MuiButton-containedPrimary,
.redeem-nft-form .MuiButton-containedPrimary:hover {
  font-family: refrigerator-deluxe, sans-serif;
  text-transform: uppercase;
  background-color: #316195;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

.redeem-nft-form .MuiButton-containedPrimary .MuiTouchRipple-root {
  margin-left: 5px;
  margin-right: 5px;
}

.redeem-nft-form .MuiButton-containedPrimary:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.redeem-nft-form .MuiButton-containedSecondary,
.redeem-nft-form .MuiButton-containedSecondary:hover {
  font-family: refrigerator-deluxe, sans-serif;
  text-transform: uppercase;
  background-color: #999;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

.redeem-nft-form .MuiButton-containedSecondary:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.redeem-nft-form .MuiFormHelperText-root {
  margin-top: 0;
}

.drop-item {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #bec2c0;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;
  line-height: 40px;
  font-weight: bold;
}
/* .drop-item .btn {
  float: right;
} */

.page .wallet-adapter-button {
  padding: 7px 20px 8px;
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 4px;
  background-color: #316195;
  text-align: center;
  transition: background-color 0.4s, opacity 0.4s, color 0.4s;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  height: auto;
}

.page .wallet-adapter-button:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.wallet-adapter-button-start-icon {
  display: none;
}

.wallet-adapter-dropdown-list {
  background-color: #fff;
}

.wallet-adapter-dropdown-list-item {
  padding: 7px 20px 8px;
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 4px;
  background-color: #316195;
  text-align: center;
  transition: background-color 0.4s, opacity 0.4s, color 0.4s;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  height: auto;
}

.wallet-adapter-dropdown-list-item:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

@media screen and (max-width: 479px) {
  .drop-item {
    text-align: center;
  }
  .drop-item .nft-name {
    display: block;
    line-height: 1.2em;
    margin-bottom: 1em;
  }
  /* .drop-item .btn {
    float: none;
  } */
}

dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.616);
  text-align: left;
}

.redeem-nft-form {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 60%);
  max-width: 600px;
  padding: 20px;
  position: fixed;
  top: 30px;
  bottom: 30px;
  overflow-y: scroll;
  text-align: left;
  z-index: 100;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1em;
}

.shoe-size-action {
  font-family: interstate, sans-serif;
  font-weight: 800;
  font-size: 13px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  margin: 0;
  padding: 0;
}

.size-chart-btn {
  float: right;
}
.size-chart-caption {
  float: left;
  display: block;
  margin: 0;
  padding: 0;
}

.shoe-size-input input {
  color: #000 !important;
  cursor: pointer !important;
  -webkit-text-fill-color: #000 !important;
}

.shoe-size-item {
  font-family: interstate, sans-serif;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 0;
  border: 1px solid #000 !important;
  min-width: 104px;
  text-align: center;
  cursor: pointer;
}

.shoe-size-item:hover {
  background: #000;
  color: #fff;
}

.shoe-sizes-table .default-size {
  font-weight: bold;
}

@media screen and (max-width: 479px) {
  .shoe-size-item {
    min-width: 98px;
  }
}

.please-wait {
  margin-bottom: 1rem;
}

.success-container p {
  text-align: left;
}
